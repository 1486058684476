import React from 'react'
import { get } from 'lodash'

const Input = props => (
  <>
    <textarea
      {...props}
      {...props.field}
      onChange={e => {
        props.form.setFieldValue(props.field.name, e.target.value)
      }}
      onBlur={e => {
        props.form.setFieldValue(props.field.name, e.target.value)
      }}
      className={
        props.readOnly
          ? `${props.classNameReadOnly} form-control-plaintext text-ellipsis`
          : (get(props.form.touched, props.field.name) ||
              props.form.submitCount > 0) &&
            get(props.form.errors, props.field.name)
          ? `${props.className} is-invalid`
          : props.className
      }
      style={{ ...props.style }}
    />
    {/* {props.meta.submitFailed && (
      <div style={{ color: 'red' }}>{props.meta.error}</div>
    )} */}
  </>
)

export default Input
