import axios, { Axios } from 'axios'
import humps from 'humps'
import Qs from 'qs'
import { objectToFormData } from 'object-to-formdata'
import downloadjs from 'downloadjs'
import moment from 'moment'
import objectPath from 'object-path'
import env from '../env'
// import { SubmissionError } from 'redux-form'
import { createBrowserHistory } from 'history'

const transformKeysToSnakeCase = object => {
  if (object instanceof File) {
    return object
  }
  if (object instanceof Array) {
    return object.map(obj => transformKeysToSnakeCase(obj))
  }
  if (object instanceof Object) {
    return Object.entries(object)
      .map(([key, value]) => [
        humps.decamelize(key),
        transformKeysToSnakeCase(value),
      ])
      .reduce(
        (memo, [key, value]) => ({
          ...memo,
          [key]: transformKeysToSnakeCase(value),
        }),
        {}
      )
  }
  return object
}

const transformKeysToCamelCase = object => {
  if (object instanceof ArrayBuffer) {
    return object
  }
  return humps.camelizeKeys(object, (key, convert) => {
    const [_, prefixUnderscores, word, suffixUnderscores] = key.match(
      /^(_*)(.*)(_*)$/
    )
    return `${prefixUnderscores}${convert(word)}${suffixUnderscores}`
  })
}

const isFileExist = object => {
  if (object instanceof File) {
    return true
  }
  if (object instanceof Array) {
    return object
      .map(obj => isFileExist(obj))
      .reduce((memo, exist) => memo || exist, false)
  }
  if (object instanceof Object) {
    return isFileExist(Object.values(object))
  }
  return false
}

const transformFormDataIfFileExist = object =>
  isFileExist(object) ? objectToFormData(object) : object

const transfromStringToDate = object => {
  if (object instanceof ArrayBuffer) {
    return object
  }
  if (object instanceof Array) {
    return object.map(obj => transfromStringToDate(obj))
  }
  if (object instanceof Object) {
    return Object.entries(object).reduce(
      (memo, [key, value]) => ({
        ...memo,
        [key]: transfromStringToDate(value),
      }),
      {}
    )
  }
  if (typeof object === 'string') {
    if (/^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2})?/.test(object)) {
      return moment(object).toDate()
    }
  }
  return object
}

const transformMomentOrDateToString = object => {
  if (object instanceof File) {
    return object
  }
  if (object instanceof Date) {
    object = moment(object)
  }
  if (object && object._isAMomentObject) {
    return object.format()
  }
  if (object instanceof Array) {
    return object.map(obj => transformMomentOrDateToString(obj))
  }
  if (object instanceof Object) {
    return Object.entries(object).reduce(
      (memo, [key, value]) => ({
        ...memo,
        [key]: transformMomentOrDateToString(value),
      }),
      {}
    )
  }
  return object
}

const arraybufferToJson = arraybuffer => {
  const decodedString = String.fromCharCode.apply(
    null,
    new Uint8Array(arraybuffer)
  )
  return JSON.parse(decodedString)
}

const defaultConfig = {
  transformRequest: [
    transformMomentOrDateToString,
    transformKeysToSnakeCase,
    transformFormDataIfFileExist,
    ...axios.defaults.transformRequest,
  ],
  transformResponse: [
    ...axios.defaults.transformResponse,
    transformKeysToCamelCase,
    transfromStringToDate,
  ],
  timeout: 30000,
  validateStatus: status => status >= 200 && status < 400,
}
export default class Api extends Axios {
  constructor(config = {}, ...rest) {
    const { requestInterceptor, responseInterceptor } = config

    super({ ...defaultConfig, ...config }, ...rest)

    this.interceptors.request.use(request => {
      requestInterceptor && requestInterceptor(request)

      const { method, params } = request
      if (method === 'get' || method === 'delete') {
        request.paramsSerializer = params => {
          return Qs.stringify(params, {
            arrayFormat: 'brackets',
            encode: false,
            strictNullHandling: true,
            // skipNulls: true,
          })
        }

        request.params = [
          transformMomentOrDateToString,
          transformKeysToSnakeCase,
          transformFormDataIfFileExist,
        ].reduce((memo, transform) => transform(memo), params)
      }

      return request
    })
    this.interceptors.response.use(
      response => {
        responseInterceptor && responseInterceptor(response)
        return response
      },
      error => {
        const response = objectPath.get(error, 'response.data', {})
        const { code, message, errors = [] } =
          response instanceof ArrayBuffer
            ? arraybufferToJson(response)
            : response

        switch (code) {
          case 'unauthorized':
          case 'not_found':
            const appStore = require('../stores/appStore').default
            appStore.logoutNoApi()
            break

          case 'FORBIDDEN':
            const history = createBrowserHistory()
            history.push('/')
            break
          case 'validate_failed':
            let validateData = errors.reduce((memo, { field, message }) => {
              field = humps.camelize(field)
              field = field.replace(/\//g, '.')

              objectPath.set(memo, field, message)
              return memo
            }, {})

            validateData._error = validateData

            alert(
              errors.map(error => `${error.field} ${error.message}`).join(', ')
            )

            // throw new SubmissionError(validateData)
            break
          default:
            // alert(message)
            break
        }

        if (objectPath.get(error, 'config.disableErrorNotification') !== true) {
          let description =
            message || errors.map(({ message }) => message).join(', ')

          if (!description || description === 'Internal server error.') {
            description = 'เกิดข้อผิดพลาดบางอย่าง กรุณาลองใหม่อีกครั้งในภายหลัง'
          }
        }

        return Promise.reject(error)
      }
    )
  }

  async get(path, params, options = {}) {
    const response = await super.get(path, { params, ...options })
    return response
  }

  async delete(path, params, options = {}) {
    const response = await super.delete(path, { params, ...options })
    return response
  }

  async download(path, params, options = {}) {
    let {
      method = 'get',
      filename,
      mimeType,
      timeout = 300000,
      ...restOptions
    } = options
    const response = await this[method](
      path,
      { pure: true, ...params },
      { responseType: 'arraybuffer', timeout, ...restOptions }
    )
    const { data, headers } = response
    filename = headers['content-disposition'].match(/filename="?(.*)"?/)[1]
    mimeType = headers['content-type']
    downloadjs(data, filename, mimeType)
  }
}

export const AUTH_TOKEN_KEY = 'Auth-token'

export const Server = {
  insuranceProduct: new Api({
    baseURL: env.apiEndpoint,
    requestInterceptor: request => {
      request.headers = {
        ...request.headers,
        [AUTH_TOKEN_KEY]: localStorage.getItem(AUTH_TOKEN_KEY),
      }
      return request
    },
  }),
}

export const insuranceProductServer = Server.insuranceProduct

insuranceProductServer.AUTH_TOKEN_KEY = AUTH_TOKEN_KEY

export const api = new Api()
