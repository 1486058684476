const Menu = [
  // {
  //   path: '/',
  //   icon: 'fa fa-th',
  //   title: 'Home',
  // },
  // { path: '/auth/login', icon: 'fa fa-key', title: 'Login' },
  {
    path: '/dashboard',
    icon: 'fa fa-th',
    title: 'Dashboard',
    roles: ['god', 'product_admin'],
  },
  { path: '/user', icon: 'ion-md-person', title: 'User', roles: ['god'] },
  {
    path: '/company',
    icon: 'fab fa-houzz',
    title: 'Company',
    roles: ['god', 'product_admin'],
  },
  {
    path: '/products',
    icon: 'fas fa-cog',
    title: 'Product',
    children: [
      { path: '/products/Life', title: 'Life' },
      { path: '/products/Health', title: 'Health' },
      { path: '/products/pa', title: 'PA' },
      { path: '/products/ta', title: 'TA' },
    ],
    roles: ['god', 'product_admin'],
  },
  {
    path: '/partner',
    icon: 'fas fa-handshake',
    title: 'Partner',
    roles: ['god'],
  },
  {
    path: '/score_star',
    icon: 'fas fa-cog',
    title: 'Score/Star',
    roles: ['god'],
    children: [
      { path: '/score_star/config', title: 'Config' },
      { path: '/score_star/mortality_base', title: 'Mortality Base' },
      { path: '/score_star/financial_budget', title: 'Financial Budget' },
    ],
  },
  {
    path: '/search',
    icon: 'ion-md-search',
    title: 'Search',
    roles: ['god', 'product_admin'],
  },

  // {
  //   path: '/menu',
  //   icon: 'fa fa-align-left',
  //   title: 'Menu Level',
  // children: [
  //   {
  //     path: '/menu/menu-1-1',
  //     title: 'Menu 1.1',
  //     children: [
  //       {
  //         path: '/menu/menu-1-1/menu-2-1',
  //         title: 'Menu 2.1',
  //         children: [
  //           { path: '/menu/menu-1-1/menu-2-1/menu-3-1', title: 'Menu 3.1' },
  //           { path: '/menu/menu-1-1/menu-2-1/menu-3-2', title: 'Menu 3.2' },
  //         ],
  //       },
  //       { path: '/menu/menu-1-1/menu-2-2', title: 'Menu 2.2' },
  //       { path: '/menu/menu-1-1/menu-2-3', title: 'Menu 2.3' },
  //     ],
  //   },
  //   { path: '/menu/menu-1-2', title: 'Menu 1.2' },
  //   { path: '/menu/menu-1-3', title: 'Menu 1.3' },
  // ],
  // },
]

export default Menu
