import React, { useState, useCallback } from 'react'
import { compose } from 'recompose'
import { withHooks, withStores } from '../../../../../enhancers'
import {
  Select,
  InputMaxMin,
} from '../../../../../components/formikComponent/index'
import { FastField } from 'formik'
import { withRouter } from 'react-router-dom'
import ParamsComponent from './Params'
import { get } from 'lodash'

const Condition = props => (
  <div className="table-responsive" style={{ overflow: 'unset' }}>
    <table
      className="table m-b-0 table-bordered table-td-valign-middle"
      style={{ background: 'white' }}
    >
      <thead>
        <tr>
          <th rowSpan="2" className="text-center">
            เพศ
          </th>
          <th colSpan="2" className="text-center">
            เริ่ม
          </th>
          <th colSpan="2" className="text-center">
            สิ้นสุด
          </th>
          <th rowSpan="2" className="text-center">
            สูตร
          </th>
          <th rowSpan="2" className="text-center">
            params
          </th>
          {!props.isEdit && props.values.benefitDetails.length > 1 && (
            <th rowSpan="2" />
          )}
        </tr>

        <tr>
          <th className="text-center">ประเภท</th>
          <th className="text-center">ค่า</th>
          <th className="text-center">ประเภท</th>
          <th className="text-center">ค่า</th>
        </tr>
      </thead>
      <tbody>
        {props.values.benefitDetails &&
          props.values.benefitDetails.map((item, index) => (
            <tr
              className="table-active text-center"
              key={`${props.name}[${index}]`}
            >
              <td style={{ width: '120px' }}>
                <FastField
                  name={`${props.name}[${index}].gender`}
                  component={Select}
                  options={get(
                    props.configs,
                    'product.benefit.died.gender.options',
                    []
                  )}
                  readOnly={props.isEdit}
                  readOnlyWidth="100px"
                  readOnlyJustifyContent="center"
                  isClearable={false}
                />
              </td>
              <td className="width-100">
                <FastField
                  name={`${props.name}[${index}].startType`}
                  component={Select}
                  options={get(
                    props.configs,
                    'product.benefit.died.startTyp.options',
                    []
                  )}
                  readOnly={props.isEdit}
                  readOnlyWidth="150px"
                  readOnlyJustifyContent="center"
                  isClearable={false}
                />
              </td>
              <td className="width-80">
                <FastField
                  name={`${props.name}[${index}].startPeriod`}
                  className="form-control"
                  classNameReadOnly="text-center"
                  component={InputMaxMin}
                  min={0}
                  max={99}
                  readOnly={props.isEdit}
                  style={{ height: '38px' }}
                />
              </td>
              <td className="width-100">
                <FastField
                  name={`${props.name}[${index}].endType`}
                  component={Select}
                  options={get(
                    props.configs,
                    'product.benefit.died.endType.options',
                    []
                  )}
                  readOnly={props.isEdit}
                  readOnlyWidth="150px"
                  readOnlyJustifyContent="center"
                  isClearable={false}
                />
              </td>
              <td className="width-80">
                <FastField
                  name={`${props.name}[${index}].endPeriod`}
                  className="form-control"
                  classNameReadOnly="text-center"
                  component={InputMaxMin}
                  min={0}
                  max={99}
                  readOnly={props.isEdit}
                  style={{ height: '38px' }}
                  required
                />
              </td>

              <ParamsComponent
                fields={`${props.name}[${index}]`}
                index={index}
                optionsFormulas={props.optionsFormulas}
                initialValueFormula={get(item, 'formula', '')}
                isEdit={props.isEdit}
                setFieldValue={props.form.setFieldValue}
              />
              {!props.isEdit && props.values.benefitDetails.length > 1 && (
                <td className="text-center">
                  <button
                    type="button"
                    className="btn btn-danger btn-icon btn-sm m-r-2"
                    onClick={props.removeItem(index)}
                  >
                    <i className="fa fa-minus"></i>
                  </button>
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </table>

    {!props.isEdit && (
      <button
        type="button"
        className="btn btn-success btn-icon btn-sm m-r-2 width-full height-30"
        onClick={props.addItem}
      >
        <i className="ion-md-add"></i>
      </button>
    )}
  </div>
)

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    configs: stores.product.configs,
  })),
  withHooks(props => {
    const [loading, setLoading] = useState(false)

    const addItem = useCallback(() => {
      props.push()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const removeItem = useCallback(index => () => props.remove(index), [])

    return {
      loading,
      setLoading,
      addItem,
      removeItem,
    }
  })
)

export default enhancer(Condition)
