import React, { useEffect } from 'react'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { compose } from 'recompose'
import { withHooks } from '../../enhancers'
import Tooltip from 'rc-tooltip'

const createSliderWithTooltip = Slider.createSliderWithTooltip
const Range = createSliderWithTooltip(Slider.Range)
const Handle = Slider.Handle

const RenderSliderInput = props => {
  const { field } = props

  return (
    <div style={{ display: 'flex' }}>
      <div>
        <input
          className={
            (props.form.touched[props.field.name] ||
              props.form.submitCount > 0) &&
            props.form.errors[props.field.name]
              ? `form-control width-70 is-invalid`
              : 'form-control width-70'
          }
          type="number"
          value={field.value && field.value[0]}
          onChange={e => {
            if (field.value) {
              if (parseInt(e.target.value) <= parseInt(field.value[1])) {
                props.form.setFieldValue(field.name, [
                  e.target.value ? parseInt(e.target.value) : 0,
                  field.value[1] ? parseInt(field.value[1]) : 0,
                ])
              }
            } else {
              props.form.setFieldValue(field.name, [0, 0])
            }
          }}
          disabled={props.readOnly}
        />
      </div>
      <div
        style={{ display: 'flex', alignItems: 'center' }}
        className="m-l-20 m-r-20"
      >
        <Range
          {...field}
          {...props}
          min={0}
          max={99}
          type="number"
          tipFormatter={value => `${value}`}
          className="width-200"
          value={field.value || [0, 0]}
          onChange={e => {
            props.form.setFieldValue(field.name, e)
          }}
          disabled={props.readOnly}
        />
      </div>
      <div>
        <input
          className={
            (props.form.touched[props.field.name] ||
              props.form.submitCount > 0) &&
            props.form.errors[props.field.name]
              ? `form-control width-70 is-invalid`
              : 'form-control width-70'
          }
          type="number"
          value={field.value && field.value[1]}
          onChange={e => {
            if (field.value) {
              if (
                parseInt(e.target.value) >= parseInt(field.value[0]) ||
                !parseInt(field.value[0])
              ) {
                props.form.setFieldValue(field.name, [
                  field.value[0] ? parseInt(field.value[0]) : 0,
                  e.target.value ? parseInt(e.target.value) : 0,
                ])
              }
            } else {
              props.form.setFieldValue(field.name, [
                0,
                e.target.value ? parseInt(e.target.value) : 0,
              ])
            }
          }}
          disabled={props.readOnly}
        />
      </div>
    </div>
  )
}

const enhancer = compose(
  withHooks(props => {
    useEffect(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSlider = props => {
      const { value, dragging, index, ...restProps } = props
      return (
        <Tooltip
          prefixCls="rc-slider-tooltip"
          overlay={value}
          visible={dragging}
          placement="top"
          key={index}
        >
          <Handle value={value} {...restProps} />
        </Tooltip>
      )
    }
    return {
      handleSlider,
      // setMax,
      // setMin,
      // min,
      // max,
    }
  })
)

export default enhancer(RenderSliderInput)
