import React from 'react'
import NumberFormat from 'react-number-format'
import { get } from 'lodash'

const Currency = props => {
  return (
    <div className="input-group width-150">
      <NumberFormat
        {...props}
        {...props.field}
        thousandSeparator={true}
        onChange={e => {
          props.form.setFieldValue(
            props.field.name,
            e.target.value.replace(/(,|-)/g, '')
          )
        }}
        onBlur={e => {
          props.form.setFieldValue(
            props.field.name,
            e.target.value.replace(/(,|-)/g, '')
          )
        }}
        className={
          props.readOnly
            ? `${props.classNameReadOnly} form-control-plaintext text-ellipsis`
            : (get(props.form.touched, props.field.name) ||
                props.form.submitCount > 0) &&
              get(props.form.errors, props.field.name)
            ? `${props.className} is-invalid`
            : props.className
        }
        style={{ ...props.style }}
      />
      <div className="input-group-append">
        <span className="input-group-text">{props.label}</span>
      </div>
    </div>
  )
}

export default Currency
