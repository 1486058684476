import ModalStore from './ModalStore'
import { insuranceProductServer } from '../common/api'

class CompanyStore extends ModalStore {
  constructor() {
    super('company', {
      all: [],
      current: {},
    })
  }

  fetch = async params => {
    const res = await insuranceProductServer.get('/companies')
    this.$setState('all', res.data.data.company)
  }

  create = async params => {
    await insuranceProductServer.post('/companies', params)
  }

  fetchById = async params => {
    const res = await insuranceProductServer.get(`/companies/${params}`)
    this.$setState('current', res.data.data.company)
  }

  update = async (id, params) => {
    await insuranceProductServer.put(`/companies/${id}`, params)
  }

  delete = async params => {
    await insuranceProductServer.delete(`/companies/${params}`)
  }
}

export default new CompanyStore()
