import React from 'react'

const Checkbox = props => (
  <div className="checkbox checkbox-css m-b-20">
    <input
      type="checkbox"
      id={`${props.input.name}_checkbox`}
      checked={props.input.value}
      onChange={props.input.onChange}
      disabled={props.readOnly}
    />
    <label htmlFor={`${props.input.name}_checkbox`}>{props.label}</label>
  </div>
)

export default Checkbox
