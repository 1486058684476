import React from 'react'
import { get } from 'lodash'

const InputPercent = props => (
  <div className="input-group width-150">
    <input
      {...props}
      {...props.field}
      onChange={e => {
        props.form.setFieldValue(props.field.name, e.target.value)
      }}
      onBlur={e => {
        props.form.setFieldValue(props.field.name, e.target.value)
      }}
      className={
        props.readOnly
          ? `${props.classNameReadOnly} form-control-plaintext text-ellipsis`
          : (get(props.form.touched, props.field.name) ||
              props.form.submitCount > 0) &&
            get(props.form.errors, props.field.name)
          ? `${props.className} is-invalid`
          : props.className
      }
      style={{ ...props.style }}
      type="number"
    />
    <div className="input-group-append">
      <span className="input-group-text">{props.label}</span>
    </div>
  </div>
)

export default InputPercent
