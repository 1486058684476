import React, { useState, useCallback, useMemo } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../../enhancers'
import { Select } from '../../../../../components/formikComponent/index'
import { Collapse, CardHeader, CardBody, Card } from 'reactstrap'
import { Field, FieldArray } from 'formik'
import TabComponent from './TabComponent'
import SweetAlert from 'react-bootstrap-sweetalert'
import { get } from 'lodash'

const CollapseComponent = props => (
  <Card className="bg-dark text-white">
    <CardHeader
      className={
        'card-header bg-dark-darker text-white pointer-cursor p-10 position-relative'
      }
    >
      <div className="d-flex justify-content-between">
        <div
          className="d-flex align-items-center"
          style={{ zIndex: 200 - props.index }}
        >
          {!props.openCollapse && (
            <i className="fas fa-chevron-down text-blue f-s-20 mr-2" />
          )}
          {props.openCollapse && (
            <i className="fas fa-chevron-up text-blue mr-2 f-s-20" />
          )}
          {/* <Field
            name={`${props.field}.premiumName`}
            component={Input}
            type="text"
            className="form-control inverse-mode width-250"
            style={{ zIndex: '11' }}
          /> */}
          <div style={{ zIndex: '11' }}>
            <Field
              name={`${props.field}.type`}
              component={Select}
              className="text-black"
              options={props.optionTypes}
              width="250px"
            />
          </div>
        </div>

        <div
          style={{
            position: 'absolute',
            left: '0',
            top: '0',
            right: '0',
            bottom: '0',
            zIndex: '10',
            cursor: 'pointer',
          }}
          onClick={props.toggleCollapse()}
        />

        <button
          type="button"
          onClick={props.onCloseAndOpenDelete}
          className="btn btn-danger btn-sm m-r-2"
          style={{ zIndex: '11' }}
        >
          Remove
        </button>
      </div>
    </CardHeader>
    <Collapse isOpen={props.openCollapse}>
      <CardBody>
        <FieldArray
          name={`${props.field}.periods`}
          index={props.index}
          render={fieldArrayProps => (
            <TabComponent
              {...fieldArrayProps}
              index={props.index}
              configs={props.configs}
              values={props.values}
              isHealth={props.isHealth}
            />
          )}
        />
      </CardBody>
    </Collapse>

    {props.confirmDelete && (
      <SweetAlert
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="default"
        title="Are you sure?"
        onConfirm={props.onDelete}
        onCancel={props.onCloseAndOpenDelete}
      >
        ต้องการลบใช่หรือไม่ ?
      </SweetAlert>
    )}
  </Card>
)

const enhancer = compose(
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [openCollapse, setOpenCollapse] = useState(false)
    const [confirmDelete, setconFirmDelete] = useState(false)

    const toggleCollapse = useCallback(
      () => () => {
        setOpenCollapse(!openCollapse)
      },
      [openCollapse]
    )

    const optionTypes = useMemo(() => {
      const options = get(props, 'configs.companies.options', []).find(
        item => item.value === props.form.values.companyId
      )
      return options ? options.companyCareerLevels : []
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.form.values.companyId, props.configs])

    const onCloseAndOpenDelete = useCallback(() => {
      setconFirmDelete(!confirmDelete)
    }, [confirmDelete])
    const onDelete = useCallback(() => {
      props.remove(props.index)
      setconFirmDelete(false)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
      loading,
      setLoading,
      toggleCollapse,
      openCollapse,
      setOpenCollapse,
      onCloseAndOpenDelete,
      confirmDelete,
      onDelete,
      optionTypes,
    }
  })
)

export default enhancer(CollapseComponent)
