import React, { useState, useCallback } from 'react'
import { compose } from 'recompose'
import { withHooks, withStores } from '../../../../../enhancers'
import { Select, Params } from '../../../../../components/formikComponent/index'
import { FastField } from 'formik'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'

const Complete = props => (
  <div className="table-responsive" style={{ overflow: 'unset' }}>
    <table
      className="table m-b-0 table-bordered"
      style={{ background: 'white' }}
    >
      <thead>
        <tr>
          <th className="text-center">เพศ</th>
          <th className="text-center">สูตร</th>
          <th className="text-center">params</th>
        </tr>
      </thead>
      <tbody>
        <tr className="table-active">
          <td className="width-150">
            <FastField
              name={`${props.name}.gender`}
              component={Select}
              options={get(
                props,
                'configs.product.benefit.died.gender.options',
                []
              )}
              readOnly={props.isEdit}
              readOnlyWidth="100px"
              readOnlyJustifyContent="center"
              isClearable={false}
            />
          </td>
          <td className="width-250">
            <FastField
              name={`${props.name}.formula`}
              component={Select}
              options={get(props, 'optionsFormulas', [])}
              readOnly={props.isEdit}
              readOnlyWidth="250px"
              readOnlyJustifyContent="center"
              isClearable={false}
            />
          </td>
          <td>
            <Params
              configs={props.findPamars()}
              name={`${props.name}`}
              isEdit={props.isEdit}
              className="m-l-10"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
)

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    configs: stores.product.configs,
  })),

  withHooks(props => {
    const [loading, setLoading] = useState(false)

    const findPamars = useCallback(() => {
      let params = []
      const newParams =
        props.values &&
        get(props, 'optionsFormulas', []).find(
          x => x.value === props.values.formula
        )

      if (newParams) {
        params = newParams.params
      }

      return params
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.values, props.optionsFormulas, props.index])

    return {
      loading,
      setLoading,
      findPamars,
    }
  })
)

export default enhancer(Complete)
