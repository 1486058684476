import ModalStore from './ModalStore'
import { insuranceProductServer } from '../common/api'

class ProfileStore extends ModalStore {
  constructor() {
    super('profileStore', {
      all: [],
      current: [],
      test: [],
      configs: [],
    })
  }

  fetch = async params => {
    const response = await insuranceProductServer.get('/users')

    this.$setState('all', response.data.data.users)
  }

  fetchById = async params => {
    const response = await insuranceProductServer.get(`/users/${params}`)

    this.$setState('current', response.data.data.user)
  }

  update = async (id, params) => {
    await insuranceProductServer.put(`/users/${id}`, params)
  }

  setTest = async params => {
    this.$setState('test', params)
  }

  fetchConfig = async params => {
    const response = await insuranceProductServer.get('/users/configs')

    this.$setState('configs', response.data.data.configs.attributes)
  }
}

export default new ProfileStore()
