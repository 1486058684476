import ModalStore from './ModalStore'
import { insuranceProductServer } from '../common/api'

class PaProduct extends ModalStore {
  constructor() {
    super('paProductStore', {
      all: [],
      current: {},
      configs: [],
    })
  }

  fetch = async param => {
    const res = await insuranceProductServer.get('/pa_products', param)
    this.$setState('all', res.data.data.products)
  }

  create = async param => {
    await insuranceProductServer.post('/pa_products', {
      ...param,
    })
  }

  fetchConfig = async () => {
    const resConfigs = await insuranceProductServer.get(`/pa_products/configs`)
    this.$setState('configs', resConfigs.data.data.configs.attributes)
  }

  fetchById = async param => {
    const res = await insuranceProductServer.get(`/pa_products/${param}`)
    const resConfigs = await insuranceProductServer.get(`/pa_products/configs`)

    this.$setState('configs', resConfigs.data.data.configs.attributes)
    this.$setState('current', res.data.data.product)
  }

  update = async (id, param) => {
    const res = await insuranceProductServer.put(`/pa_products/${id}`, {
      ...param,
    })
    this.$setState('current', res.data.data.product)
  }

  delete = async id => {
    await insuranceProductServer.delete(`/pa_products/${id}`)
  }

  updateStatusPublish = async (id, param) => {
    const res = await insuranceProductServer.post(`/pa_products/${id}/publish`)
    const response = res.data.data

    const products = this.$getState('all')

    await products.forEach(product => {
      if (product.id === response.product.id) {
        product.status = response.product.status
      }
    })

    this.$setState('all', products)
  }

  updateStatusUnpublished = async (id, param) => {
    const res = await insuranceProductServer.post(
      `/pa_products/${id}/unpublished`
    )
    const response = res.data.data

    const products = this.$getState('all')

    await products.forEach(product => {
      if (product.id === response.product.id) {
        product.status = response.product.status
      }
    })

    this.$setState('all', products)
  }

  clearData = () => {
    this.$setState('configs', [])
    this.$setState('current', [])
  }
}

export default new PaProduct()
