import React from 'react'
import { Field } from 'formik'
import { Textarea, Select } from './index'
import InputUnit from './InputUnit'
import CurrencyUnit from './CurrencyUnit'
import InputPercent from './InputPercent'

const componentTypeMapping = {
  text: Textarea,
  integer: InputUnit,
  percent: InputPercent,
  selector: Select,
  currency: CurrencyUnit,
  none: null,
}

const Params = props => (
  <div className="d-flex flex-row">
    {props.configs.map(item => (
      <Field
        key={`${props.name}.${item.name}`}
        name={`${props.name}.params.${item.name}`}
        component={componentTypeMapping[item.type]}
        className={`form-control m-l-5 ${props.className}`}
        label={item.label}
        options={item.options || []}
      />
    ))}
  </div>
)

export default Params
