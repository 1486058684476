import React, { useState, useContext, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { PageSettings } from '../../config/page-settings.js'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'recompose'
import { withHooks, withStores } from '../../enhancers/index.js'
import { Input } from '../../components/index.js'

const validate = values => {
  const errors = {}

  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }

  if (values.confirmPassword && values.password) {
    if (values.confirmPassword !== values.password) {
      errors.confirmPassword = 'Passwords do not match. '
    }
  }

  return errors
}

const Register = props => (
  <div className="login login-v1">
    <div className="login-container">
      <div className="login-header">
        <div className="brand">
          <span className="logo"></span> <b>Insurance</b> Admin
          <small>the most complete insurance management system</small>
        </div>
        <div className="icon">
          <i className="fa fa-lock"></i>
        </div>
      </div>
      <div className="login-body">
        <div className="login-content">
          <form
            className="margin-bottom-0"
            onSubmit={props.handleSubmit(async values => {
              await props.register(values)
              props.history.push('/auth/login')
            })}
          >
            <div className="form-group m-b-20">
              <Field
                name="email"
                component={Input}
                type="text"
                className="form-control form-control-lg inverse-mode"
                placeholder="Email Address"
                required
              />
            </div>
            <div className="form-group m-b-20">
              <Field
                name="password"
                component={Input}
                type="password"
                className="form-control form-control-lg inverse-mode"
                placeholder="Password"
                required
              />
            </div>
            <div className="form-group m-b-20">
              <Field
                name="confirmPassword"
                component={Input}
                type="password"
                className="form-control form-control-lg inverse-mode"
                placeholder="Confirm Password"
                required
              />
            </div>

            <div className="form-group m-b-20">
              <Field
                name="nameInfo.firstName"
                component={Input}
                type="text"
                className="form-control form-control-lg inverse-mode"
                classNameReadOnly="width-150 "
                placeholder="First Name"
                required
                readOnly={props.isEdit}
              />
            </div>

            <div className="form-group m-b-20">
              <Field
                name="nameInfo.lastName"
                component={Input}
                type="text"
                className="form-control form-control-lg inverse-mode"
                classNameReadOnly="width-150"
                placeholder="Last Name"
                required
                readOnly={props.isEdit}
              />
            </div>

            <div className="login-buttons">
              <button
                type="submit"
                className="btn btn-success btn-block btn-lg"
                disabled={props.loading}
              >
                Sign me up
              </button>
              {/* {props.token} */}
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
)

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    register: stores.app.register,
    token: stores.app.token,
  })),
  reduxForm({
    form: 'registerForm',
    validate,
  }),
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const context = useContext(PageSettings)

    useEffect(() => {
      context.handleSetPageSidebar(false)
      context.handleSetPageHeader(false)
      context.handleSetPageContentFullWidth(true)

      return () => {
        context.handleSetPageSidebar(true)
        context.handleSetPageHeader(true)
        context.handleSetPageContentFullWidth(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { loading, setLoading }
  })
)

export default enhancer(Register)
