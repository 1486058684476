import React from 'react'
import NumberFormat from 'react-number-format'
import { get } from 'lodash'

const Currency = props => {
  return (
    <NumberFormat
      thousandSeparator={true}
      {...props.field}
      onChange={e => {
        props.form.setFieldValue(
          props.field.name,
          e.target.value.replace(/(,|-)/g, '')
        )
      }}
      onBlur={e => {
        props.form.setFieldValue(
          props.field.name,
          e.target.value.replace(/(,|-)/g, '')
        )
      }}
      {...props}
      className={
        props.readOnly
          ? `${props.classNameReadOnly} form-control-plaintext text-ellipsis`
          : (get(props.form.touched, props.field.name) ||
              props.form.submitCount > 0) &&
            get(props.form.errors, props.field.name)
          ? `${props.className} is-invalid`
          : props.className
      }
      style={{ ...props.style }}
    />
  )
}

export default Currency
