import ModalStore from './ModalStore'
import { insuranceProductServer } from '../common/api'

class financialBudgetStore extends ModalStore {
  constructor() {
    super('financialBudgetStore', {
      all: [],
      currentYear: [],
      current: {},
      configs: {},
    })
  }

  fetch = async params => {
    const res = await insuranceProductServer.get(
      '/noon_star/financial_budget_table'
    )
    this.$setState('all', res.data.data.years)
  }

  fetchDetail = async id => {
    const res = await insuranceProductServer.get(
      `/noon_star/financial_budget_table/${id}`
    )
    this.$setState('currentYear', res.data.data.financialBudgetTable)
  }

  fetchConfig = async params => {
    const res = await insuranceProductServer.get(
      `/noon_star/financial_budget_table/configs`
    )
    this.$setState('configs', res.data.data.configs.attributes)
  }

  create = async params => {
    const res = await insuranceProductServer.post(
      `/noon_star/financial_budget_table`,
      params
    )
    this.$setState('currentYear', res.data.data.financialBudgetTable)
  }
  update = async (id, params) => {
    const res = await insuranceProductServer.put(
      `/noon_star/financial_budget_table/${id}`,
      params
    )
    this.$setState('currentYear', res.data.data.financialBudgetTable)
  }
  delete = async id => {
    await insuranceProductServer.delete(
      `/noon_star/financial_budget_table/${id}`
    )
  }
}

export default new financialBudgetStore()
