import React, { useState, useCallback } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../../enhancers'
import { Input } from '../../../../../components/formikComponent/index'
import flatten from 'flat'
import { FastField } from 'formik'

import { withRouter } from 'react-router-dom'
import { get } from 'lodash'

const TableComponent = props => (
  <div className="table-responsive ">
    <table className="table m-b-0 table-bordered">
      <thead>
        <tr style={{ background: '#1d2226' }}>
          <th className="text-center text-silver-lighter p-b-15 height-50">
            อายุ
          </th>
          <th className="text-center text-silver-lighter p-b-15">ชาย</th>
          <th className="text-center text-silver-lighter p-b-15">หญิง</th>
        </tr>
      </thead>
      <tbody>
        {Array(100)
          .fill(0)
          .map((item, index) => {
            if (parseInt(props.min) <= index && index <= parseInt(props.max)) {
              return (
                <tr className="table-active" key={`${props.name}[${index}]`}>
                  <td
                    style={{ textAlign: 'center', paddingTop: '20px' }}
                    key={`${props.name}[${index}].age`}
                  >
                    <FastField
                      name={`${props.name}[${index}].age`}
                      component={Input}
                      type="text"
                      className="form-control form-control-lg inverse-mode"
                      hidden
                    />
                    {index}
                  </td>
                  {get(props.form, 'values.maleMinAgeYear', -1) <= index &&
                  get(props.form, 'values.maleMaxAgeYear', -1) >= index ? (
                    <td key={`${props.name}[${index}].male`}>
                      <FastField
                        name={`${props.name}[${index}].male`}
                        component={Input}
                        type="text"
                        className="form-control form-control-lg inverse-mode"
                        required
                        readOnly={props.isEdit}
                        onChange={props.onChangeMale(index)}
                      />
                    </td>
                  ) : (
                    <td />
                  )}
                  {get(props.form, 'values.femaleMinAgeYear', -1) <= index &&
                  get(props.form, 'values.femaleMaxAgeYear', -1) >= index ? (
                    <td key={`${props.name}[${index}].female`}>
                      <FastField
                        required
                        readOnly={props.isEdit}
                        name={`${props.name}[${index}].female`}
                        component={Input}
                        type="text"
                        className="form-control form-control-lg inverse-mode"
                        onChange={props.onChangeFemale(index)}
                      />
                    </td>
                  ) : (
                    <td />
                  )}
                </tr>
              )
            } else {
              return null
            }
          })}
      </tbody>
    </table>
  </div>
)

const enhancer = compose(
  withRouter,

  withHooks(props => {
    const [loading, setLoading] = useState(false)

    const onChangeMale = useCallback(
      index => e => {
        let valueTable
        if (props.match.path.indexOf('health') !== -1) {
          //subString for chrome browser
          // valueTable = value
          //   .replace(/฿ /g, '')
          //   .split('\t')
          //   .map(row => row.split(' ').splice(1, 2))
          // valueTable.splice(0, 1)

          //subString for firefox browser
          valueTable = e.target.value
            .replace(/฿ /g, '')
            .replace(/\t/g, '')
            .split(' ')

          for (let i = 0; i < valueTable.length; i++) {
            valueTable.splice(i, 1)
          }
          const newArr = []
          while (valueTable.length) newArr.push(valueTable.splice(0, 2))
          valueTable = newArr
        } else {
          valueTable = e.target.value.split(' ').map(row => row.split('\t'))
        }

        if (Object.keys(flatten(valueTable)).length <= 1) {
          props.form.setFieldValue(
            `${props.name}[${index}].male`,
            e.target.value.replace(/(,|-)/g, '')
          )
          return
        }
        e.preventDefault()

        valueTable.forEach((cols, y) => {
          let [male, female] = cols

          if (props.match.path.indexOf('health') !== -1) {
            if (cols.length > 2) {
              male = cols[0]
              female = cols[1]
            }
          } else {
            if (cols.length > 2) {
              male = cols[cols.length - 2]
              female = cols[cols.length - 1]
            }
          }

          props.form.setFieldValue(
            `${props.name}.${index + y}.male`,
            Number(male.replace(/[^0-9.-]+/g, ''))
          )
          props.form.setFieldValue(
            `${props.name}.${index + y}.female`,
            Number(female.replace(/[^0-9.-]+/g, ''))
          )
        })
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    const onChangeFemale = useCallback(
      index => e => {
        let valueTable
        if (props.match.path.indexOf('health') !== -1) {
          valueTable = e.target.value
            .replace(/฿ /g, '')
            .split('\t')
            .map(row => row.split(' ').splice(1, 2))

          valueTable.splice(0, 1)
        } else {
          valueTable = e.target.value.split(' ').map(row => row.split('\t'))
        }

        if (Object.keys(flatten(valueTable)).length <= 1) {
          props.form.setFieldValue(
            `${props.name}[${index}].female`,
            e.target.value.replace(/(,|-)/g, '')
          )
          return
        }

        valueTable.forEach((cols, y) => {
          let [male, female] = cols

          if (props.match.path.indexOf('health') !== -1) {
            if (cols.length > 2) {
              male = cols[0]
              female = cols[1]
            }
            if (y === 0) {
              female = cols[1]
            }
          } else {
            if (cols.length > 2) {
              male = cols[cols.length - 2]
              female = cols[cols.length - 1]
            }

            if (y === 0) {
              female = cols[cols.length - 1]
            }
          }

          e.preventDefault()
          if (y > 0) {
            props.form.setFieldValue(`${props.name}.${index + y}.male`, male)
          }

          props.form.setFieldValue(`${props.name}.${index + y}.female`, female)
        })
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    return {
      loading,
      setLoading,
      onChangeMale,
      onChangeFemale,
    }
  })
)

export default enhancer(TableComponent)
