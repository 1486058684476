import ModalStore from './ModalStore'
import { insuranceProductServer } from '../common/api'

class DashboardStore extends ModalStore {
  constructor() {
    super('dashboardStore', {
      all: [],
      current: [],
      test: [],
      configs: [],
    })
  }

  fetch = async params => {
    const response = await insuranceProductServer.get('/dashboard')

    this.$setState('all', response.data.data.dashboard)
  }
}

export default new DashboardStore()
