import ModalStore from './ModalStore'
import { insuranceProductServer } from '../common/api'

class ScoreStarConfig extends ModalStore {
  constructor() {
    super('scoreStarConfigStore', {
      current: [],
      test: [],
      configs: [],
    })
  }

  fetch = async params => {
    const response = await insuranceProductServer.get('/noon_star_config')

    this.$setState('current', response.data.data.noonStarConfig)
  }

  update = async params => {
    const response = await insuranceProductServer.put(`/noon_star_config`, {
      ...params,
    })

    this.$setState('current', response.data.data.noonStarConfig)
  }

  fetchConfig = async params => {
    const response = await insuranceProductServer.get(
      '/noon_star_config/configs'
    )

    this.$setState('configs', response.data.data.configs.attributes)
  }
}

export default new ScoreStarConfig()
