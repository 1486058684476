import React, { useState, useContext, useEffect } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { PageSettings } from '../../config/page-settings.js'
import { Field, reduxForm } from 'redux-form'
import { compose } from 'recompose'
import { withHooks, withStores } from '../../enhancers/index.js'
import { Input, Checkbox } from '../../components/index.js'

const Login = props => (
  <div className="login login-v1">
    <div className="login-container">
      <div className="login-header">
        <div className="brand">
          <span className="logo"></span> <b>Insurance</b> Admin
          <small>the most complete insurance management system</small>
        </div>
        <div className="icon">
          <i className="fa fa-lock"></i>
        </div>
      </div>
      <div className="login-body">
        <div className="login-content">
          <form
            className="margin-bottom-0"
            onSubmit={props.handleSubmit(async values => {
              await props.login(values)
              if (values.agree) {
                localStorage.setItem('email', values.email)
                localStorage.setItem('agree', true)
              } else {
                localStorage.removeItem('email')
                localStorage.removeItem('agree')
              }
              await props.history.push('/dashboard')
              window.location.reload()
            })}
          >
            <div className="form-group m-b-20">
              <Field
                name="email"
                component={Input}
                type="text"
                className="form-control form-control-lg inverse-mode"
                placeholder="Email Address"
                required
              />
            </div>
            <div className="form-group m-b-20">
              <Field
                name="password"
                component={Input}
                type="password"
                className="form-control form-control-lg inverse-mode"
                placeholder="Password"
                required
              />
            </div>
            {props.submitFailed && (
              <div style={{ color: 'red' }}>email หรือ password ไม่ถูกต้อง</div>
            )}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Field name="agree" component={Checkbox} label="Remember Me" />
              <Link to="/auth/register" style={{ paddingTop: '5px' }}>
                Register
              </Link>
            </div>
            <div className="login-buttons">
              <button
                type="submit"
                className="btn btn-success btn-block btn-lg"
                disabled={props.loading}
              >
                Sign me in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
)

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    login: stores.app.login,
    token: stores.app.token,
    initialValues: {
      email: localStorage.getItem('email'),
      agree: localStorage.getItem('agree'),
    },
  })),
  reduxForm({
    form: 'loginForm',
  }),
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const context = useContext(PageSettings)

    useEffect(() => {
      context.handleSetPageSidebar(false)
      context.handleSetPageHeader(false)
      context.handleSetPageContentFullWidth(true)

      return () => {
        context.handleSetPageSidebar(true)
        context.handleSetPageHeader(true)
        context.handleSetPageContentFullWidth(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { loading, setLoading }
  })
)

export default enhancer(Login)
