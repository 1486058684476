import React from 'react'
import { compose } from 'recompose'
import { withHooks } from '../enhancers'

const CheckboxGroup = props => {
  return (
    <div>
      {props.options.map(({ label, value }) => (
        <div className="checkbox checkbox-css m-b-10" key={value}>
          <input
            type="checkbox"
            id={'CHECKBOX-GROUP' + value}
            checked={(props.input.value || []).includes(value)}
            onChange={checked => {
              props.onChange(value, checked.target.checked)
            }}
            disabled={props.readOnly}
          />
          <label htmlFor={'CHECKBOX-GROUP' + value}>{label}</label>
        </div>
      ))}
      {props.meta.submitFailed && props.meta.error && (
        <div style={{ color: 'red' }}>* {props.meta.error}</div>
      )}
    </div>
  )
}

const enhancer = compose(
  withHooks(props => {
    const onChange = (value, checked) => {
      const data = (props.input.value || []).filter(v => v !== value) || ''

      if (checked) {
        data.push(value)
      }

      props.input.onChange(data)
    }

    return {
      onChange,
    }
  })
)

export default enhancer(CheckboxGroup)
