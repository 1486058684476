import React from 'react'
import Select from 'react-select'
import { get } from 'lodash'

const RenderSelectInput = props => {
  const { field, options } = props

  let custom = {
    container: base => ({ ...base, width: props.width }),
    menu: base => ({ ...base, zIndex: 201 }),
  }
  if (props.readOnly) {
    custom = {
      control: () => ({
        border: 'unset',
        width: props.readOnlyWidth || '300px',
        height: props.readOnlyHeight || '40px',
        padding: 'unset',
      }),
      indicatorsContainer: () => ({ display: 'none' }),
      container: () => ({ pointerEvents: 'none' }),
      singleValue: () => ({
        display: 'flex',
        // justifyContent: 'center',
        width: '100%',
        fontSize: props.readOnlyfontSize || '16px',
        paddingTop: props.readOnlyPaddingTop || '8px',
        marginLeft: props.readOnlyMarginLeft || '-8px',
      }),
      placeholder: () => ({ display: 'none' }),
    }
  }
  if (
    (get(props.form.touched, props.field.name) || props.form.submitCount > 0) &&
    get(props.form.errors, props.field.name)
  ) {
    custom = {
      control: base => ({
        ...base,
        borderColor: '#ff5b57',
      }),
    }
  }

  return (
    <Select
      {...field}
      name={field.name}
      id={field.name}
      isSearchable
      required={props.required}
      value={options.find(x => x.value === field.value) || ''}
      onChange={value => {
        if (value) {
          props.form.setFieldValue(field.name, value.value)
        } else {
          props.form.setFieldValue(field.name, value)
        }
      }}
      isClearable
      {...props}
      options={options}
      className={props.className}
      isDisabled={props.disabled}
      styles={custom}
      defaultValue={props.defaultValue}
    />
  )
}

export default RenderSelectInput
