import React from 'react'

const Switch = props => {
  return (
    <div className="switcher switcher-success">
      <input
        id={props.field.name}
        checked={props.field.value}
        value={props.field.value}
        disabled={!props.readOnly}
        {...props}
        {...props.field}
        type="checkbox"
      />
      <label htmlFor={props.field.name}></label>
    </div>
  )
}

export default Switch
