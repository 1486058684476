import ModalStore from './ModalStore'
import { insuranceProductServer, AUTH_TOKEN_KEY } from '../common/api'

class AppStore extends ModalStore {
  constructor() {
    super('app', {
      token: localStorage.getItem(AUTH_TOKEN_KEY) || '',
      current: [],
    })
  }

  login = async params => {
    const response = await insuranceProductServer.post('/auth/sign_in', params)

    this.$setState('token', response.data.data.token)

    localStorage.setItem(AUTH_TOKEN_KEY, response.data.data.token)
  }

  auth = async params => {
    const response = await insuranceProductServer.get('/users/info')

    this.$setState('current', response.data.data.user)

    // localStorage.setItem(AUTH_TOKEN_KEY, response.data.data.token)
  }

  register = async param => {
    await insuranceProductServer.post('/auth/register', {
      ...param,
    })
  }

  logout = async param => {
    await insuranceProductServer.post('/auth/sign_out')
    await localStorage.removeItem('Auth-token')
  }

  logoutNoApi = async () => {
    await localStorage.removeItem('Auth-token')
  }
}

export default new AppStore()
