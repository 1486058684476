import React from 'react'
import NumberFormat from 'react-number-format'

const Currency = props => {
  return (
    <>
      <NumberFormat
        {...props}
        thousandSeparator={true}
        value={props.input.value}
        className={
          props.readOnly
            ? `${props.classNameReadOnly} form-control-plaintext text-ellipsis`
            : props.meta.touched && props.meta.error
            ? `${props.className} is-invalid`
            : props.className
        }
        onChange={e => {
          props.input.onChange(e.target.value.replace(/(,|-)/g, ''))
        }}
        style={{ textAlign: 'center', ...props.style }}
      />

      {props.meta.submitFailed && (
        <div style={{ color: 'red' }}>{props.meta.error}</div>
      )}
    </>
  )
}

export default Currency
