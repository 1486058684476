import React from 'react'
import { Link } from 'react-router-dom'
import { PageSettings } from './../../config/page-settings.js'
import { withRouter } from 'react-router-dom'
import { withStores, withHooks } from '../../enhancers'
import { compose } from 'recompose'
import { useState } from 'react'

class SidebarProfile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      profileActive: 0,
    }
    this.handleProfileExpand = this.handleProfileExpand.bind(this)
  }

  handleProfileExpand(e) {
    e.preventDefault()
    this.setState(state => ({
      profileActive: !this.state.profileActive,
    }))
  }

  render() {
    return (
      <PageSettings.Consumer>
        {({ pageSidebarMinify }) => (
          <ul className="nav">
            <li
              className={
                'nav-profile ' + (this.state.profileActive ? 'expand ' : '')
              }
            >
              <Link to="/" onClick={this.handleProfileExpand}>
                <div className="cover with-shadow" />
                <div className="image image-icon bg-black text-grey-darker width-30 height-30">
                  {this.props.current.logo ? (
                    <img
                      src={this.props.current.logo}
                      className="width-30 height-30 rounded-corner m-r-0"
                      alt="Profile logo"
                    />
                  ) : (
                    <i className="fa fa-user" />
                  )}
                </div>
                <div className="info">
                  {/* <b className="caret pull-right" /> */}
                  {this.props.current.nameInfo &&
                    this.props.current.nameInfo.firstName}{' '}
                  {this.props.current.nameInfo &&
                    this.props.current.nameInfo.lastName}
                  <small>
                    <div className="text-ellipsis">
                      {this.props.current.email}
                    </div>
                  </small>
                </div>
              </Link>
            </li>
            {/* <li>
              <ul
                className={
                  'nav nav-profile ' +
                  (this.state.profileActive && !pageSidebarMinify
                    ? 'd-block '
                    : '')
                }
              >
                <li>
                  <Link to="/">
                    <i className="fa fa-cog" /> Settings
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <i className="fa fa-pencil-alt" /> Send Feedback
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <i className="fa fa-question-circle" /> Helps
                  </Link>
                </li>
              </ul>
            </li> */}
          </ul>
        )}
      </PageSettings.Consumer>
    )
  }
}

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    current: stores.app.current,
  })),

  withHooks(props => {
    const [loading, setLoading] = useState(false)

    return { loading, setLoading }
  })
)

export default enhancer(SidebarProfile)
