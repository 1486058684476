import ModalStore from './ModalStore'
import { insuranceProductServer } from '../common/api'

class PartnerStore extends ModalStore {
  constructor() {
    super('partnerStore', {
      all: [],
      current: [],
      test: [],
      configs: [],
    })
  }

  fetch = async params => {
    const res = await insuranceProductServer.get('/partners')
    this.$setState('all', res.data.data.partners)
  }

  create = async params => {
    await insuranceProductServer.post('/partners', params)
  }

  fetchConfig = async () => {
    const resConfigs = await insuranceProductServer.get(`/partners/configs`)

    // const typeOptions = []
    // const subTypeOptions = []
    // const productOptions = []
    // let productOnSubtpye = {}
    // let productOnType = {}

    // await resConfigs.data.data.configs.company.forEach(company => {
    //   const newType = []
    //   company.type.forEach(type => {
    //     const newSubType = []

    //     type.subtype.forEach(subtype => {
    //       if (subtype.products.length > 0) {
    //         newSubType.push(subtype)
    //         subtype.products.forEach(product => {
    //           productOptions.push(product)
    //         })
    //       }

    //       if (productOnSubtpye[subtype.label]) {
    //         productOnSubtpye[subtype.label] = [
    //           ...productOnSubtpye[subtype.label],
    //           ...subtype.products,
    //         ]
    //       } else {
    //         productOnSubtpye = {
    //           ...productOnSubtpye,
    //           [subtype.label]: subtype.products,
    //         }
    //       }
    //       if (
    //         subTypeOptions.map(item => item.value).indexOf(subtype.value) ===
    //           -1 &&
    //         subtype.products.length > 0
    //       ) {
    //         subTypeOptions.push({ label: subtype.label, value: subtype.value })
    //       }
    //     })

    //     type.subtype = newSubType
    //     if (
    //       typeOptions.map(item => item.value).indexOf(type.value) === -1 &&
    //       type.subtype.length > 0
    //     ) {
    //       typeOptions.push({ label: type.label, value: type.value })
    //     }

    //     if (type.subtype.length > 0) {
    //       newType.push(type)
    //     }
    //   })
    //   company.type = newType
    // })

    // console.log({
    //   ...resConfigs.data.data.configs,
    //   typeOptions,
    //   subTypeOptions,
    //   productOptions,
    //   productOnSubtpye,
    // })

    this.$setState(
      'configs',
      resConfigs.data.data.configs
      // {
      //   ...resConfigs.data.data.configs,
      //   typeOptions,
      //   subTypeOptions,
      //   productOptions,
      // }
    )
  }

  fetchById = async params => {
    const res = await insuranceProductServer.get(`/partners/${params}`)
    this.$setState('current', res.data.data.partner)
  }

  update = async (id, params) => {
    await insuranceProductServer.put(`/partners/${id}`, params)
  }

  delete = async params => {
    await insuranceProductServer.delete(`/partners/${params}`)
  }
}

export default new PartnerStore()
