import React, { useState, useEffect, useCallback } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../enhancers/index.js'
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert'
import { withRouter } from 'react-router-dom'

const Products = props => (
  <>
    <form
      style={{ alignItems: 'center', display: 'flex' }}
      onSubmit={props.onUpdateStatus}
    >
      <div className="switcher switcher-success">
        <input
          type="checkbox"
          id={'status' + props.index}
          onChange={props.onClickStatus}
          checked={props.status}
          disabled={false}
        />
        <label htmlFor={'status' + props.index}></label>
      </div>
      {props.status && (
        <span className="label label-info m-r-3 width-40">online</span>
      )}
      {!props.status && (
        <span className="label label-danger m-r-3 width-40">offine</span>
      )}
    </form>
    {props.confirmChangeStatus && (
      <SweetAlert
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="default"
        title="Are you sure update status?"
        onConfirm={props.onUpdateStatus}
        onCancel={props.onStatusCancel}
      >
        ต้องการ update status ใช่หรือไม่ ?
      </SweetAlert>
    )}
  </>
)

const enhancer = compose(
  withRouter,
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [confirmChangeStatus, setConfirmChangeStatus] = useState(false)
    const [status, setStatus] = useState(false)

    useEffect(() => {
      setStatus(props.initialValues)
    }, [props.initialValues])

    const onUpdateStatus = useCallback(() => {
      if (status) {
        props.updateStatusPublish(props.id, 'online')
      } else {
        props.updateStatusUnpublished(props.id, 'offline')
      }

      setConfirmChangeStatus(!confirmChangeStatus)

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, confirmChangeStatus, props.id])

    const onStatusCancel = useCallback(() => {
      setStatus(!status)
      setConfirmChangeStatus(!confirmChangeStatus)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [confirmChangeStatus])

    const onClickStatus = useCallback(() => {
      setStatus(!status)
      setConfirmChangeStatus(!confirmChangeStatus)
    }, [confirmChangeStatus, status])

    return {
      loading,
      setLoading,
      onClickStatus,
      confirmChangeStatus,
      onUpdateStatus,
      onStatusCancel,
      status,
      setStatus,
    }
  })
)

export default enhancer(Products)
