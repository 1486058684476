import React from 'react'
import Select from 'react-select'
import FixRequiredSelect from './FixRequiredSelect'

const RenderSelectInput = props => {
  const { input, options } = props
  let custom = {}
  if (props.readOnly) {
    custom = {
      control: () => ({
        border: 'unset',
        width: props.readOnlyWidth || '300px',
        height: props.readOnlyHeight || '40px',
        padding: 'unset',
      }),
      indicatorsContainer: () => ({ display: 'none' }),
      container: () => ({ pointerEvents: 'none' }),
      singleValue: () => ({
        display: 'flex',
        justifyContent: props.readOnlyJustifyContent,
        width: '100%',
        fontSize: props.readOnlyfontSize || '16px',
        paddingTop: props.readOnlyPaddingTop || '8px',
        marginLeft: props.readOnlyMarginLeft || '-8px',
      }),
      placeholder: () => ({ display: 'none' }),
    }
  }
  if (props.meta.touched && props.meta.error) {
    custom = {
      control: base => ({
        ...base,
        borderColor: '#ff5b57',
      }),
    }
  }
  return (
    <FixRequiredSelect
      {...input}
      SelectComponent={Select}
      isSearchable
      required={props.required}
      value={options.find(x => x.value === input.value) || ''}
      onChange={value =>
        value ? input.onChange(value.value) : input.onChange(value)
      }
      onBlur={() => input.onBlur(input.value)}
      options={options}
      className={props.className}
      isDisabled={props.disabled}
      styles={custom}
      defaultValue={props.defaultValue}
      isClearable
    />
  )
}

export default RenderSelectInput
