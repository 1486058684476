import { findKey } from 'lodash'
import { isClient } from './common/helper'

const config = {
  production: {},
  uat: {},
  staging: {
    name: 'Staging',
    siteRoot: 'https://insurance-product.tqld.xyz',
    apiEndpoint: 'https://insurance-product-api.tqld.xyz/backoffice/api/v1',
    slackWebhookUrl:
      'https://hooks.slack.com/services/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',
  },
  develop: {},
  local: {
    name: 'Local',
    apiEndpoint: 'http://localhost:51303/backoffice/api/v1',
    slackWebhookUrl:
      'https://hooks.slack.com/services/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',
  },
}

const getEnvByHost = () =>
  isClient
    ? findKey(config, {
        siteRoot: window.location.origin,
      })
    : null

export const env = process.env.APP_ENV || getEnvByHost() || 'local'

export default config[env]
