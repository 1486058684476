import React from 'react'

const Input = props => (
  <>
    <input
      {...props}
      value={props.input.value}
      onChange={props.input.onChange}
      className={
        props.readOnly
          ? `${props.classNameReadOnly} form-control-plaintext text-ellipsis`
          : props.meta.touched && props.meta.error
          ? `${props.className} is-invalid`
          : props.className
      }
      style={{ ...props.style }}
    />
    {/* {props.meta.submitFailed && (
      <div style={{ color: 'red' }}>{props.meta.error}</div>
    )} */}
  </>
)

export default Input
