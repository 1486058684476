import React from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../enhancers'

const Radio = props => {
  return (
    <div>
      {props.options.map(({ label, value }) => (
        <div className="radio radio-css m-b-10" key={value}>
          <input
            type="radio"
            id={'RADIO' + value}
            checked={props.field.value === value}
            onChange={checked => {
              props.form.setFieldValue(props.field.name, value)
            }}
            disabled={props.readOnly}
          />
          <label htmlFor={'RADIO' + value}>{label}</label>
        </div>
      ))}
      {/* {props.meta.submitFailed && props.meta.error && (
        <div style={{ color: 'red' }}>* {props.meta.error}</div>
      )} */}
    </div>
  )
}

const enhancer = compose(
  withHooks(props => {
    return {}
  })
)

export default enhancer(Radio)
