import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import SidebarNavList from './sidebar-nav-list.jsx'
import menus from './menu.jsx'
import { compose } from 'redux'
import { withStores } from '../../enhancers/index.js'

class SidebarNav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: -1,
      clicked: -1,
    }
  }

  handleExpand(e, i, match) {
    e.preventDefault()

    if (this.state.clicked === -1 && match) {
      this.setState(state => ({
        active: -1,
        clicked: 1,
      }))
    } else {
      this.setState(state => ({
        active: this.state.active === i ? -1 : i,
        clicked: 1,
      }))
    }
  }

  render() {
    return (
      <ul className="nav">
        <li className="nav-header">Navigation</li>
        {menus.map((menu, i) => {
          const checkRoles =
            this.props.current.roles &&
            this.props.current.roles.some(roles =>
              menu.roles.some(item => roles === item)
            )
          if (checkRoles || menu.roles.length === 0) {
            return (
              <Route
                path={menu.path}
                exact={menu.exact}
                key={i}
                children={({ match }) => (
                  <SidebarNavList
                    data={menu}
                    key={i}
                    expand={e => this.handleExpand(e, i, match)}
                    active={i === this.state.active}
                    clicked={this.state.clicked}
                  />
                )}
              />
            )
          }

          return null
        })}
      </ul>
    )
  }
}

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    auth: stores.app.auth,
    current: stores.app.current,
    logout: stores.app.logout,
    token: stores.app.token,
  }))
)
export default enhancer(SidebarNav)
