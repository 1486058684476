import React from 'react'

const InputMaxMin = props => (
  <>
    <input
      {...props.input}
      onChange={e => {
        if (
          parseInt(e.target.value) >= parseInt(parseInt(props.min)) &&
          parseInt(e.target.value) <= parseInt(parseInt(props.max))
        ) {
          props.input.onChange(e.target.value)
        }

        if (parseInt(e.target.value) < parseInt(props.min)) {
          props.input.onChange(props.min)
        }

        if (parseInt(e.target.value) > parseInt(props.max)) {
          props.input.onChange(props.max)
        }

        if (!e.target.value) {
          props.input.onChange(undefined)
        }
      }}
      onBlur={e => {
        if (
          parseInt(e.target.value) >= parseInt(props.min) &&
          parseInt(e.target.value) <= parseInt(props.max)
        ) {
          props.input.onChange(e.target.value)
        }

        if (parseInt(e.target.value) < parseInt(props.min)) {
          props.input.onChange(props.min)
        }

        if (parseInt(e.target.value) > parseInt(props.max)) {
          props.input.onChange(props.max)
        }
        if (!e.target.value) {
          props.input.onChange(undefined)
        }
      }}
      {...props}
      type="number"
      className={
        props.readOnly
          ? `${props.classNameReadOnly} form-control-plaintext text-ellipsis`
          : props.meta.touched && props.meta.error
          ? `${props.className} is-invalid`
          : props.className
      }
      style={{ ...props.style }}
    />
    {/* {props.meta.submitFailed && (
      <div style={{ color: 'red' }}>{props.meta.error}</div>
    )} */}
  </>
)

export default InputMaxMin
