import React, { useState, useCallback, useEffect } from 'react'
import { compose } from 'recompose'
import { withHooks, withStores } from '../../../../../enhancers'
import { Select, Params } from '../../../../../components/formikComponent/index'
import { FastField } from 'formik'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'

const Condition = props => (
  <>
    <td className="width-250">
      <FastField
        name={`${props.fields}.formula`}
        component={Select}
        options={get(props, 'optionsFormulas', [])}
        onChange={props.onChangeFormula}
        readOnly={props.isEdit}
        readOnlyWidth="250px"
        readOnlyJustifyContent="center"
        required
        isClearable={false}
      />
    </td>
    <td>
      <Params
        configs={props.findPamars(props.index)}
        name={`${props.fields}`}
        isEdit={props.isEdit}
      />
    </td>
  </>
)

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    configs: stores.product.configs,
  })),
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [formula, setFormula] = useState('')

    const findPamars = useCallback(
      index => {
        let params = []
        const newParams = get(props, 'optionsFormulas', []).find(
          x => x.value === formula
        )

        if (newParams) {
          params = newParams.params
        }

        return params
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [props.fields, props.optionsFormulas, formula]
    )

    useEffect(() => {
      setFormula(props.initialValueFormula)
    }, [props.initialValueFormula])

    const onChangeFormula = useCallback(e => {
      props.setFieldValue(`${props.fields}.formula`, e.value)
      setFormula(e.value)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return {
      loading,
      setLoading,
      findPamars,
      onChangeFormula,
    }
  })
)

export default enhancer(Condition)
