import ModalStore from './ModalStore'
import { insuranceProductServer } from '../common/api'

class MortalityBaseStore extends ModalStore {
  constructor() {
    super('mortalityBaseStore', {
      all: [],
      current: {},
      configs: {},
    })
  }

  fetch = async params => {
    const res = await insuranceProductServer.get(
      '/noon_star/mortality_base_table'
    )
    this.$setState('all', res.data.data.noonStar)
  }

  create = async params => {
    await insuranceProductServer.post('/noon_star/mortality_base_table', params)
  }

  fetchById = async params => {
    const res = await insuranceProductServer.get(
      `/noon_star/mortality_base_table/${params}`
    )
    this.$setState('current', res.data.data.noonStar)
  }

  update = async (id, params) => {
    const res = await insuranceProductServer.put(
      `/noon_star/mortality_base_table/${id}`,
      params
    )
    this.$setState('current', res.data.data.noonStar)
  }

  delete = async params => {
    await insuranceProductServer.delete(
      `/noon_star/mortality_base_table/${params}`
    )
  }

  fetchConfig = async params => {
    const response = await insuranceProductServer.get(
      '/noon_star/mortality_base_table/configs'
    )

    this.$setState('configs', response.data.data.configs.attributes)
  }
}

export default new MortalityBaseStore()
