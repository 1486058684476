import { createStore, combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { reducer as modalReducer } from './ModalStore'

const rootReducer = combineReducers({
  form: formReducer,
  model: modalReducer,
})

const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store
