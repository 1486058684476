import React, { useState, useCallback } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../../enhancers'
import {
  Select,
  CurrencyInput,
} from '../../../../../components/formikComponent/index'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import { Field, FieldArray } from 'formik'
import classnames from 'classnames'
import TablePage from './TablePage'
import AddPeriod from './AddPeriod'
import SetupDiscount from '../setupDiscount/index'
import SweetAlert from 'react-bootstrap-sweetalert'
import { get } from 'lodash'

const TabComponent = props => (
  <>
    <Nav tabs>
      {props.values.periods &&
        props.values.periods.map((field, index) => (
          <NavItem key={`${props.name}[${index}]`}>
            <NavLink
              className={classnames({
                active: props.activeTab === index,
                [classnames]: 'bg-grey',
              })}
              onClick={props.toggleTab(index)}
            >
              <div className="d-flex align-items-end">
                <Field
                  name={`${props.name}[${index}].period`}
                  component={Select}
                  type="text"
                  className="width-150"
                  readOnlyWidth="150px"
                  readOnlyHeight="29px"
                  readOnlyfontSize="12px"
                  options={
                    (props.configs.product &&
                      props.configs.product.premiumMaster.period.options) ||
                    []
                  }
                  readOnly
                />

                <button
                  type="button"
                  onClick={props.onCloseAndOpenDelete}
                  className="btn btn-danger btn-icon  btn-sm m-r-2"
                >
                  <i className="ion-md-trash" />
                </button>
              </div>
            </NavLink>
          </NavItem>
        ))}

      <AddPeriod
        open={props.openModalAdd}
        setOpen={props.onClickAdd}
        fields={(props.values.periods && props.values.periods) || []}
        form={`${props.name}`}
        setActiveTab={props.setActiveTab}
        push={props.push}
        insert={props.insert}
      />
    </Nav>
    {(props.values.periods && props.values.periods.length) > 0 && (
      <TabContent activeTab={props.activeTab}>
        {props.values.periods &&
          props.values.periods.map((field, index) => (
            <TabPane tabId={index} key={`${props.name}[${index}]Tab`}>
              <div
                style={{
                  background: '#1d2226',
                }}
                className="p-5 height-50 d-flex justify-content-center"
              >
                <div className="d-flex align-items-center flex-column justify-content-center">
                  <div className="text-silver-lighter width-full d-flex align-items-center">
                    อัตราเบี้ยประกันมาตรฐานรายปีต่อจํานวนเงินเอาประกัน
                    <Field
                      required
                      name={`${props.name}[${index}].premiumOfSumInsured`}
                      component={CurrencyInput}
                      type="text"
                      className="form-control form-control-lg inverse-mode width-xs height-30 m-l-20 m-r-20"
                      classNameReadOnly="width-xs height-30 text-white"
                      readOnly={props.isEdit}
                    />{' '}
                    บาท
                  </div>
                </div>
              </div>
              <FieldArray
                name={`${props.name}[${index}].premiumDetails`}
                render={fieldArrayprops => (
                  <TablePage
                    {...fieldArrayprops}
                    premiumMasterIndex={props.index}
                    periodsIndex={index}
                    values={field}
                    configs={props.configs}
                    min={Math.min(
                      get(props.form, 'values.femaleMinAgeYear', -1),
                      get(props.form, 'values.maleMinAgeYear', -1)
                    )}
                    max={Math.max(
                      get(props.form, 'values.femaleMaxAgeYear', -1),
                      get(props.form, 'values.maleMaxAgeYaer', -1)
                    )}
                  />
                )}
              />

              {!props.isHealth && (
                <div
                  style={{
                    background: '#1d2226',
                  }}
                  className="m-t-20 d-flex justify-content-center"
                >
                  <div className="d-flex align-items-center flex-column justify-content-center height-50">
                    <div className="text-silver-lighter width-full d-flex align-items-center">
                      <b> ตางรางส่วนลด</b>
                    </div>
                  </div>
                </div>
              )}

              {!props.isHealth && (
                <FieldArray
                  name={`${props.name}[${index}].discounts`}
                  render={fieldArray => (
                    <SetupDiscount
                      {...fieldArray}
                      isEdit={props.isEdit}
                      values={field}
                    />
                  )}
                />
              )}
              {props.confirmDelete && (
                <SweetAlert
                  showCancel
                  confirmBtnText="Continue"
                  confirmBtnBsStyle="primary"
                  cancelBtnBsStyle="default"
                  title="Are you sure?"
                  onConfirm={props.onDelete(index)}
                  onCancel={props.onCloseAndOpenDelete}
                >
                  ต้องการลบใช่หรือไม่ ?
                </SweetAlert>
              )}
            </TabPane>
          ))}
      </TabContent>
    )}
  </>
)

const enhancer = compose(
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState(0)
    const [openModalAdd, setOpenModalAdd] = useState(false)
    const [confirmDelete, setconFirmDelete] = useState(false)

    const toggleTab = useCallback(
      tab => () => {
        if (activeTab !== tab) {
          setActiveTab(tab)
        }
      },
      [activeTab]
    )

    const onClickAdd = useCallback(() => {
      setOpenModalAdd(!openModalAdd)
    }, [openModalAdd])

    const onCloseAndOpenDelete = useCallback(() => {
      setconFirmDelete(!confirmDelete)
    }, [confirmDelete])

    const onDelete = useCallback(
      index => () => {
        props.remove(index)
        if (index - 1 < 0) {
          setActiveTab(index)
        } else {
          setActiveTab(index - 1)
        }

        setconFirmDelete(false)
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      []
    )

    return {
      loading,
      setLoading,
      toggleTab,
      activeTab,
      setActiveTab,
      onClickAdd,
      openModalAdd,
      onCloseAndOpenDelete,
      onDelete,
      confirmDelete,
    }
  })
)

export default enhancer(TabComponent)
