import React from 'react'
import Dropzone from 'react-dropzone'
import { compose } from 'recompose'
import { withHooks } from '../enhancers'

const Avatar = props => (
  <div style={{ pointerEvents: props.readOnly ? 'none' : 'auto' }}>
    {props.input.value && !props.readOnly && (
      <button
        onClick={props.onClear}
        className="btn btn-danger btn-icon btn-circle btn-sm m-r-2 "
        style={{ position: 'absolute', left: '115px', top: '0px' }}
      >
        <i className="fa fa-times" />
      </button>
    )}

    <Dropzone onDrop={props.onDrop} id="55555555">
      {({ getRootProps, getInputProps }) => (
        <section>
          <div
            className="width-150 height-150 rounded-corner bg-grey-transparent-2 text-center p-auto"
            data-cy="drag-and-drop"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {!props.input.value && (
              <>
                <i className="fa fa-upload fa-3x m-t-40" />
                <div className="m-t-15">Choose File</div>
              </>
            )}
            {props.input.value && (
              <>
                <img
                  src={props.value}
                  className="width-150 height-150 rounded-corner bg-grey-transparent-2 text-center p-auto"
                  alt=""
                />
              </>
            )}
          </div>
        </section>
      )}
    </Dropzone>
  </div>
)

const enhancer = compose(
  withHooks(props => {
    let testValue
    const onDrop = acceptedFiles => {
      testValue = acceptedFiles
      props.input.onChange(
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        })
      )
    }

    const onClear = () => {
      props.input.onChange('')
    }

    let value

    if (props.input.value.preview) value = props.input.value.preview
    else {
      value = props.input.value
      props.input.onChange(props.input.value)
    }
    return { onDrop, onClear, value, testValue }
  })
)

export default enhancer(Avatar)
