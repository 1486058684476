import React, { useEffect } from 'react'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { compose } from 'recompose'
import { withHooks } from '../enhancers'
import Tooltip from 'rc-tooltip'

const Handle = Slider.Handle

const RenderSliderInput = props => {
  const { input } = props

  return (
    <div style={{ display: 'flex' }}>
      <div>
        <input
          className="form-control width-60"
          type="number"
          value={input.value || 0}
          onChange={e => {
            input.onChange(parseInt(e.target.value))
          }}
          disabled={props.readOnly}
        />
      </div>
      <div
        style={{ display: 'flex', alignItems: 'center' }}
        className="m-l-20 m-r-20"
      >
        <Slider
          min={0}
          max={99}
          className="width-300"
          handle={props.handleSlider}
          value={input.value || 0}
          onChange={e => {
            input.onChange(e)
          }}
          onBlur={() => input.onBlur(input.value)}
          disabled={props.readOnly}
        />
      </div>
    </div>
  )
}

const enhancer = compose(
  withHooks(props => {
    useEffect(() => {
      props.input.onChange(0)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSlider = props => {
      const { value, dragging, index, ...restProps } = props
      return (
        <Tooltip
          prefixCls="rc-slider-tooltip"
          overlay={value}
          visible={dragging}
          placement="top"
          key={index}
        >
          <Handle value={value} {...restProps} />
        </Tooltip>
      )
    }
    return {
      handleSlider,
      // setMax,
      // setMin,
      // min,
      // max,
    }
  })
)

export default enhancer(RenderSliderInput)
