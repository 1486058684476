import React, { useState, useCallback } from 'react'
import { compose } from 'recompose'
import { withHooks } from '../../../../../enhancers'
import { Collapse, CardHeader, CardBody, Card } from 'reactstrap'
import { Select } from '../../../../../components/formikComponent/index'
import { FastField, FieldArray } from 'formik'
import Conditions from './Conditions'
import SweetAlert from 'react-bootstrap-sweetalert'
import Complete from './CompleteTheContract'
import { get } from 'lodash'

const CollapseComponent = props => (
  <Card className="bg-dark text-white">
    <CardHeader className="card-header bg-dark-darker text-white pointer-cursor p-5 position-relative">
      <div className="d-flex justify-content-between p-l-10 p-r-10">
        <div
          className="d-flex justify-content-between"
          style={{ pointerEvents: 'none' }}
        >
          {!props.openCollapse && (
            <i className="fas fa-chevron-down text-blue mr-2 f-s-17 d-flex align-items-center" />
          )}
          {props.openCollapse && (
            <i className="fas fa-chevron-up text-blue mr-2 f-s-17 d-flex align-items-center" />
          )}
          <FastField
            name={`${props.field}.type`}
            component={Select}
            options={get(props.configs, 'product.benefit.type.options', [])}
            className="width-250"
            style={{ zIndex: '11' }}
            readOnly
            required
          />
        </div>
        <div
          style={{
            position: 'absolute',
            left: '0',
            top: '0',
            right: '0',
            bottom: '0',
            zIndex: '10',
            cursor: 'pointer',
          }}
          onClick={props.toggleCollapse()}
        />
        {!props.isEdit && (
          <button
            type="button"
            onClick={props.onCloseAndOpenDelete}
            className="btn btn-danger btn-sm m-r-2"
            style={{ zIndex: '11' }}
          >
            Remove
          </button>
        )}
      </div>
    </CardHeader>
    <Collapse isOpen={props.openCollapse}>
      <CardBody>
        {(props.type === 'compensation' ||
          props.type === 'died' ||
          props.type === 'death_protect_guarantee' ||
          props.type === 'pension') && (
          <FieldArray
            name={`${props.field}.benefitDetails`}
            render={fieldArrayProps => (
              <Conditions
                {...fieldArrayProps}
                optionsFormulas={props.optionsFormulas}
                configs={props.configs}
                isEdit={props.isEdit}
                values={props.values}
              />
            )}
          />
        )}
        {props.type === 'complete_the_contract' && (
          <Complete
            index={props.index}
            values={props.values}
            name={`${props.field}`}
            component={Complete}
            optionsFormulas={props.optionsFormulas}
            configs={props.configs}
            isEdit={props.isEdit}
          />
        )}
      </CardBody>
    </Collapse>

    {props.confirmDelete && (
      <SweetAlert
        showCancel
        confirmBtnText="Continue"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="default"
        title="Are you sure?"
        onConfirm={props.onDelete}
        onCancel={props.onCloseAndOpenDelete}
      >
        ต้องการลบใช่หรือไม่ ?
      </SweetAlert>
    )}
  </Card>
)

const enhancer = compose(
  withHooks(props => {
    const [loading, setLoading] = useState(false)
    const [openCollapse, setOpenCollapse] = useState(false)
    const [confirmDelete, setconFirmDelete] = useState(false)

    const toggleCollapse = useCallback(
      () => () => {
        setOpenCollapse(!openCollapse)
      },
      [openCollapse]
    )

    const onCloseAndOpenDelete = useCallback(() => {
      setconFirmDelete(!confirmDelete)
    }, [confirmDelete])
    const onDelete = useCallback(() => {
      props.remove(props.index)
      setconFirmDelete(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.index])
    let optionsFormulas

    if (props.values.type === 'died' && props.configs.product) {
      optionsFormulas = props.configs.product.benefit.died.formula.options
    } else if (
      props.values.type === 'complete_the_contract' &&
      props.configs.product
    ) {
      optionsFormulas =
        props.configs.product.benefit.completeTheContract.formula.options
    } else if (props.configs.product) {
      optionsFormulas =
        props.configs.product.benefit.compensation.formula.options
    }

    return {
      loading,
      setLoading,
      toggleCollapse,
      openCollapse,
      setOpenCollapse,
      onCloseAndOpenDelete,
      confirmDelete,
      onDelete,
      type: props.values.type,
      optionsFormulas,
    }
  })
)

export default enhancer(CollapseComponent)
