import React, { useState, useCallback } from 'react'
import { compose } from 'recompose'
import { withStores, withHooks } from '../../../../../enhancers'
import { FastField } from 'formik'
import {
  Select,
  Params,
  CurrencyInput,
} from '../../../../../components/formikComponent/index'
// const Params = props => {
//   switch (props.formula) {
//     case 'percent':
//       return (
//         <div className="input-group width-150">
//           <Field
//             name={`${props.name}.percent`}
//             className="form-control"
//             component={Input}
//           />

//           <div className="input-group-append">
//             <span className="input-group-text">%</span>
//           </div>
//         </div>
//       )
//     case 'baht':
//       return (
//         <div className="input-group width-150">
//           <Field
//             name={`${props.name}.baht`}
//             className="form-control"
//             component={Input}
//           />

//           <div className="input-group-append">
//             <span className="input-group-text">฿</span>
//           </div>
//         </div>
//       )

//     default:
//       return null
//   }
// }

const SetupDisCount = props => (
  <div className="table-responsive" style={{ overflow: 'unset' }}>
    <table
      className="table m-b-0 table-bordered table-td-valign-middle"
      style={{ background: 'white' }}
    >
      <thead>
        <tr style={{ background: '#1d2226' }}>
          <th className="text-center text-silver-lighter p-b-15 height-50">
            เพศ
          </th>
          <th className="text-center text-silver-lighter p-b-15">ทุนขั้นต่ำ</th>
          <th className="text-center text-silver-lighter p-b-15">สูตร</th>
          <th className="text-center text-silver-lighter p-b-15">params</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {props.values.discounts &&
          props.values.discounts.map((item, index) => (
            <tr
              className="table-active"
              key={`${props.name}[${index}]` + index}
            >
              <td className="width-150">
                <FastField
                  name={`${props.name}[${index}].gender`}
                  component={Select}
                  options={props.configs.gender.options}
                  required
                />
              </td>

              <td className="width-150">
                <FastField
                  name={`${props.name}[${index}].moreSumInsured`}
                  component={CurrencyInput}
                  className=" form-control "
                  required
                />
              </td>

              <td className="width-150">
                <FastField
                  name={`${props.name}[${index}].formula`}
                  component={Select}
                  options={props.configs.formula.options}
                  required
                />
              </td>
              <td>
                <Params
                  configs={props.findPamars(index)}
                  name={`${props.name}[${index}]`}
                />
              </td>
              <td className="text-center">
                <button
                  type="button"
                  className="btn btn-danger btn-icon  btn-sm m-r-2 text-center"
                  onClick={props.removeItem(index)}
                >
                  <i className="fa fa-minus"></i>
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>

    <button
      type="button"
      className="btn btn-success btn-icon  btn-sm m-r-2 width-full"
      onClick={props.addItem}
    >
      <i className="ion-md-add"></i>
    </button>
  </div>
)

const enhancer = compose(
  withStores(stores => ({
    configs:
      stores.product.configs.product && stores.product.configs.product.discount,
  })),

  withHooks(props => {
    const [loading, setLoading] = useState(false)

    const findPamars = useCallback(
      index => {
        let params = []
        const newParams =
          props.values.discounts[index] &&
          props.configs.formula.options.find(
            x => x.value === props.values.discounts[index].formula
          )

        if (newParams) {
          params = newParams.params
        }

        return params
      },
      [props.values.discounts, props.configs]
    )

    const addItem = useCallback(() => {
      props.push()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const removeItem = useCallback(index => () => props.remove(index), [])

    return {
      loading,
      setLoading,
      findPamars,
      addItem,
      removeItem,
    }
  })
)

export default enhancer(SetupDisCount)
