import React from 'react'
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { withStores, withHooks } from '../../../enhancers'
import { useState } from 'react'
import { useEffect } from 'react'

class DropdownProfile extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      dropdownOpen: false,
    }
  }

  toggle() {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }))
  }

  render() {
    // console.log('header', this.props)
    return (
      <Dropdown
        isOpen={this.state.dropdownOpen}
        toggle={this.toggle}
        className="dropdown navbar-user"
        tag="li"
      >
        <DropdownToggle tag="a">
          <div className="image image-icon bg-black text-grey-darker ">
            {this.props.current.logo ? (
              <img
                src={this.props.current.logo}
                className="width-30 height-30 rounded-corner m-r-0"
                alt="Profile logo"
              />
            ) : (
              <i className="fa fa-user" />
            )}
          </div>
          <span className="d-none d-md-inline">{this.props.current.email}</span>{' '}
          <b className="caret" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu dropdown-menu-right" tag="ul">
          <DropdownItem onClick={this.props.onClickEditProfile}>
            Edit Profile
          </DropdownItem>
          <div className="dropdown-divider" />
          <DropdownItem onClick={this.props.onClickLogout}>
            Log Out
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    )
  }
}

const enhancer = compose(
  withRouter,
  withStores(stores => ({
    current: stores.app.current,
    logout: stores.app.logout,
  })),

  withHooks(props => {
    const [loading, setLoading] = useState(false)

    const onClickLogout = async () => {
      await props.logout()
      props.history.push('/auth/login')
      // window.location.reload()
    }

    const onClickEditProfile = () => {
      props.history.push(`/user/${props.current.id}`)
    }

    useEffect(() => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { loading, setLoading, onClickLogout, onClickEditProfile }
  })
)

export default enhancer(DropdownProfile)
