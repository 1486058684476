import { connect } from 'react-redux'
import deepmerge from 'deepmerge'
import appStore from '../stores/appStore'
import productStore from '../stores/lifeProductStore'
import searchStore from '../stores/searchStore'
import companyStore from '../stores/companyStore'
import profileStore from '../stores/profileStore'
import healthProductStore from '../stores/healthProductStore'
import dashboardStore from '../stores/dashboardStore'
import partnerStore from '../stores/partnerStore'
import mortalityBaseStore from '../stores/mortalityBaseStore'
import paProductStore from '../stores/paProductStore'
import taProductStore from '../stores/taProductStore'
import scoreStarConfigStore from '../stores/ScoreStarConfig'
import financialBudgetStore from '../stores/financialBudgetStore'

const storeMapping = {
  app: appStore,
  product: productStore,
  search: searchStore,
  company: companyStore,
  profileStore,
  healthProductStore,
  dashboardStore,
  partnerStore,
  mortalityBaseStore,
  paProductStore,
  taProductStore,
  scoreStarConfigStore,
  financialBudgetStore,
}

const withStores = mapStoresToProps =>
  connect(state => {
    const stores = deepmerge(storeMapping, state.model)
    return mapStoresToProps(stores)
  })

export default withStores
