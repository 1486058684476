import ModalStore from './ModalStore'
import { insuranceProductServer } from '../common/api'

class SeacrhStore extends ModalStore {
  constructor() {
    super('search', { all: [], current: [], searchConfig: [] })
  }
  fetch = async param => {
    let res
    if (param.type === 'ci') {
      delete param.room
      delete param.totalAnnualBenefits
    }
    if (param.type === 'hs') {
      delete param.sumInsured
    }

    if (param.productType === 'healthConfigs') {
      param.productType = 'HealthProduct'
      // res = await insuranceProductServer.get('/health_products/search', param)
    } else if (param.productType === 'lifeConfigs') {
      param.productType = 'LifeProduct'
      // res = await insuranceProductServer.get('/life_products/search', param)
    } else if (param.productType === 'taConfigs') {
      param.productType = 'TaProduct'
      // res = await insuranceProductServer.get('/ta_products/search', param)
    } else if (param.productType === 'paConfigs') {
      param.productType = 'PaProduct'
      // res = await insuranceProductServer.get('/pa_products/search', param)
    }

    res = await insuranceProductServer.get('/search', param)
    this.$setState('all', res.data.data.products)
    this.$setState('searchConfig', res.config.params)
  }

  lifeFetchById = async (id, params) => {
    const res = await insuranceProductServer.get(
      `/life_products/${id}/detail`,
      {
        ...params,
      }
    )

    this.$setState('current', res.data.data.product)
  }

  healthFetchById = async (id, params) => {
    const res = await insuranceProductServer.get(
      `/health_products/${id}/details`,
      {
        ...params,
      }
    )

    this.$setState('current', res.data.data.product)
  }

  taFetchById = async (id, params) => {
    const res = await insuranceProductServer.get(`/ta_products/${id}/details`, {
      ...params,
    })

    this.$setState('current', res.data.data.product)
  }

  paFetchById = async (id, params) => {
    const res = await insuranceProductServer.get(`/pa_products/${id}/details`, {
      ...params,
    })

    this.$setState('current', res.data.data.product)
  }

  fetchConfig = async () => {
    const resConfigs = await insuranceProductServer.get(`/search/configs`)
    this.$setState('searchConfig', resConfigs.data.data)
  }
}

export default new SeacrhStore()
