import React from 'react'
import { get } from 'lodash'

const InputMaxMin = props => (
  <>
    <input
      {...props.field}
      onChange={e => {
        if (
          parseInt(parseInt(e.target.value)) >= parseInt(parseInt(props.min)) &&
          parseInt(e.target.value) <= parseInt(parseInt(props.max))
        ) {
          props.form.setFieldValue(props.field.name, e.target.value)
        }

        if (parseInt(e.target.value) < parseInt(props.min)) {
          props.form.setFieldValue(props.field.name, props.min)
        }

        if (parseInt(e.target.value) > parseInt(props.max)) {
          props.form.setFieldValue(props.field.name, props.max)
        }

        if (!e.target.value) {
          props.form.setFieldValue(props.field.name, undefined)
        }
      }}
      onBlur={e => {
        if (
          parseInt(e.target.value) >= parseInt(props.min) &&
          parseInt(e.target.value) <= parseInt(props.max)
        ) {
          props.form.setFieldValue(props.field.name, e.target.value)
        }

        if (parseInt(e.target.value) < parseInt(props.min)) {
          props.form.setFieldValue(props.field.name, props.min)
        }

        if (parseInt(e.target.value) > parseInt(props.max)) {
          props.form.setFieldValue(props.field.name, props.max)
        }
        if (!e.target.value) {
          props.form.setFieldValue(props.field.name, undefined)
        }
      }}
      {...props}
      type="number"
      className={
        props.readOnly
          ? `${props.classNameReadOnly} form-control-plaintext text-ellipsis`
          : (get(props.form.touched, props.field.name) ||
              props.form.submitCount > 0) &&
            get(props.form.errors, props.field.name)
          ? `${props.className} is-invalid`
          : props.className
      }
      style={{ ...props.style }}
    />
    {/* {props.meta.submitFailed && (
      <div style={{ color: 'red' }}>{props.meta.error}</div>
    )} */}
  </>
)

export default InputMaxMin
